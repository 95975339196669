@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@400;700&display=swap');

@font-face {
  font-family: 'Nasalization';
  src: url('../assets/fonts/nasalization-rg.otf') format('opentype');
}

:root {
  --dthRed: #de0e19;
  --temproOrange: #f78a35;
  --andromedaBlue: #0070c8;
  --andromedaLightBlue: #0090ff;
  --font: -apple-system, 'system-ui', 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body {
  font-family: var(--font);
}

.main {
  min-height: calc(100vh - 105px);
}

.andromeda-logo {
  font-family: 'Nasalization', sans-serif;
  color: white;
  font-size: 1.75rem;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

/* Simple hover style for logo with no glow */
.logo-link:hover .andromeda-logo {
  color: #ffffff;
  transform: scale(1.02);
}

/* Clean hover style for navigation links in drawer */
.MuiListItemButton-root:hover .MuiTypography-root {
  color: var(--andromedaLightBlue) !important;
  font-weight: 700 !important;
}

/* Clean style for button hover */
.MuiButton-root:hover {
  color: white;
}

/* High contrast for navbar buttons with underline effect */
.MuiAppBar-root .MuiButton-root {
  position: relative;
  overflow: hidden;
}

.MuiAppBar-root .MuiButton-root::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: white;
  transition: width 0.3s ease;
}

.MuiAppBar-root .MuiButton-root:hover::after {
  width: 100%;
}

.bg-red {
  background-color: var(--andromedaLightBlue);
}

.card-title {
  color: black;
}

.font-w600 {
  font-weight: 600;
}

.page-heading {
  text-transform: capitalize;
  color: var(--andromedaLightBlue);
  text-align: center;
}

.center-box {
  height: calc(100vh - 80px);
}

.register-box {
  margin-top: 175px;
}

.notification {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loginlogo {
  max-height: 40px;
  max-width: 100%;
  margin: 15px auto;
  justify-self: center;
}

.list-style-position {
  list-style-position: inside;
}

.gender {
  border: 0px solid #ced4da;
}

hr {
  border-top: 5px solid var(--andromedaLightBlue);
}

.react-confirm-alert-overlay {
  background: rgba(220, 220, 220, 0.8) !important;
}

.custom-alert {
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  padding: 40px;
  margin: 0 20px;
}

.custom-alert h1 {
  color: var(--andromedaLightBlue);
  text-align: center;
}

.custom-alert .warning-buttons {
  display: flex;
  justify-content: space-between;
}

.custom-alert .cancel {
  background-color: white;
  border-radius: 20px;
  margin-right: 20px;
  width: 100px;
  border: 1px black solid;
}

.custom-alert .change {
  background-color: green;
  border-radius: 20px;
  width: 100px;
}

.custom-alert .stop {
  background-color: var(--andromedaLightBlue);
  border-radius: 20px;
  width: 100px;
}

.navbar {
  background-color: var(--andromedaBlue);
}

.nav-link {
  color: white !important;
  font-weight: 600;
}

.navbar-toggler {
  background-color: white;
}

.language {
  text-align: right;
  font-size: 1.1rem;
}

.language .langbox {
  display: inline;
}

.language .langbox #language {
  border: 0;
  background-color: white;
}

.language .icon {
  transform: translateY(-3px);
}

.user-id {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1rem;
}

.user-id a {
  color: black;
}

.text-red {
  color: var(--andromedaLightBlue);
}

.border-bottom-solid {
  border-bottom: solid;
}

.text-green {
  color: #358dc9;
}

.bg-gray {
  background-color: #eeeeee;
}

.btn-logout {
  background-color: white;
  color: var(--andromedaLightBlue);
}

.btn-startJob {
  color: var(--andromedaLightBlue);
  background-color: white;
  border: 1px solid gray;
}

.btn-stopWork {
  background-color: #ff3333;
  color: white;
}

.startWork {
  display: flex;
  justify-content: center;
}

.select-center {
  text-align-last: center;
}

option {
  text-align: center;
}

.card-body {
  padding: 0 !important;
}

.icon-color {
  color: var(--andromedaLightBlue);
}

.cursor-pointer {
  cursor: pointer;
}

.h7 {
  font-size: 0.75rem;
}

.pagination p {
  margin-bottom: 0;
}

.border-only-right {
  border-bottom: 0 !important;
  border-right: 1px solid #dee2e6;
}

.g-3 {
  gap: 1rem;
}

.d-grid {
  display: grid;
}

.w-120 {
  width: 120px;
}

.dropzone {
  border: 5px dashed #ced4da;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  background-color: #f8f9fa;
  cursor: pointer;
}

.dropzone-accepted {
  border-color: green;
}

.dropzone-declined {
  border-color: red;
}

.dialog-image {
  object-fit: cover;
  width: 100%;
}

.ReactSwipeButton {
  float: left;
  width: 100%;
  height: 50px;
  position: relative;
}

.ReactSwipeButton,
.ReactSwipeButton * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rsbContainer {
  float: left;
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}

.rsbContainerUnlocked {
  width: 50% !important;
  margin-left: 25%;
  transition: 0.5s;
  cursor: default;
}

.rsbContainerUnlocked .rsbcSlider {
  left: 100% !important;
  cursor: default;
  pointer-events: none;
}

.rsbContainerUnlocked .rsbcSliderArrow {
  transition: 0.5s;
  margin-right: -60px;
}

.rsbContainerUnlocked .rsbcSliderCircle {
  transition: 0.5s;
  margin-right: -60px;
}

.rsbcSlider {
  float: left;
  width: 100%;
  position: absolute;
  height: 50px;
  top: 0;
  left: 50px;
  margin-left: -100%;
  background: #333;
  border-radius: 25px;
  z-index: 100;
  cursor: pointer;
}

.rsbcSliderText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 50px;
  text-align: center;
  font-family: var(--font);
  letter-spacing: 2px;
  color: #fff;
  font-size: 13px;
}

.rsbcSliderArrow {
  float: left;
  position: absolute;
  transform: rotate(45deg);
  border: 2px solid #fff;
  height: 8px;
  width: 8px;
  top: 50%;
  right: 22px;
  margin-top: -6px;
  border-left-color: transparent;
  border-bottom-color: transparent;
  transform-origin: center;
  z-index: 10;
}

.rsbcSliderCircle {
  position: absolute;
  right: 0;
  background: #444;
  top: 0;
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.rsbcText {
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-family: var(--font);
  letter-spacing: 2px;
  font-size: 13px;
  color: black;
}

ol li::marker {
  color: var(--andromedaLightBlue);
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  box-shadow: 0 0 0px 1000px #fff inset !important;
  -webkit-text-fill-color: inherit !important;
  transition: background-color 5000s ease-in-out 0s;
}

@media screen and (max-width: 768px) {
  .c-mb-md-3 {
    margin-bottom: 1rem !important;
  }
}

@media only screen and (max-width: 576px) {
  .c-my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .c-mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
